/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {NavLink, useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {RoutesHelper} from '../../../../shared/helper/RoutesHelper'

type props = {
  user: any
}

const DetailsHeader: React.FC<props> = ({user}) => {
  const intl = useIntl()
  const id = useParams().id
  const current_user = JSON.parse(localStorage.getItem('current_user') || '')

  let activeClassName = 'nav-link text-active-primary me-5 active'

  return (
    <>
      <div className='d-flex'>
        <ul className='nav nav-stretch nav-line-tabs border-transparent fs-5 fw-bolder flex-nowrap d-block d-lg-flex d-md-flex d-sm-flex'>
          {(RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2', 'hr3']) || current_user?.id === user?.id) && (
            <li className='nav-item'>
              <NavLink
                className={({isActive}) => (isActive ? activeClassName : 'nav-link me-5 ms-0')}
                to={`/user/${id}/details/personal/view`}
              >
                {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS'})}
              </NavLink>
            </li>
          )}
          <li className='nav-item'>
            <NavLink
              className={({isActive}) => (isActive ? activeClassName : 'nav-link me-5 ms-0')}
              to={`/user/${id}/details/business/view`}
            >
              {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS'})}
            </NavLink>
          </li>
          {(RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2', 'hr3']) || current_user?.id === user?.id) && (
            <li className='nav-item'>
              <NavLink
                className={({isActive}) => (isActive ? activeClassName : 'nav-link me-5 ms-0')}
                to={`/user/${id}/details/family/view`}
              >
                {intl.formatMessage({id: 'ACCOUNT.FAMILY_DETAILS'})}
              </NavLink>
            </li>
          )}
          {(RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2', 'hr3']) || current_user?.id === user?.id) && (
            <li className='nav-item'>
              <NavLink
                className={({isActive}) => (isActive ? activeClassName : 'nav-link me-5 ms-0')}
                to={`/user/${id}/details/settings/view`}
              >
                {intl.formatMessage({id: 'ACCOUNT.SETTINGS'})}
              </NavLink>
            </li>
          )}
          {(RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2', 'hr3']) || current_user?.id === user?.id) && (
            <li className='nav-item'>
              <NavLink
                className={({isActive}) => (isActive ? activeClassName : 'nav-link me-5 ms-0')}
                to={`/user/${id}/details/documentation/view`}
              >
                {intl.formatMessage({id: 'ACCOUNT.ADDITIONAL_DOCUMENTATION'})}
              </NavLink>
            </li>
          )}
          {(RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2', 'hr3']) || current_user?.id === user?.id) && (
            <li className='nav-item'>
              <NavLink
                className={({isActive}) => (isActive ? activeClassName : 'nav-link me-5 ms-0')}
                to={`/user/${id}/details/vacation/view`}
              >
                {intl.formatMessage({id: 'ACCOUNT.HEADER.VACATION'})}
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </>
  )
}
export {DetailsHeader}
