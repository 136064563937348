import React, {useState} from 'react'
import {User} from '../../../shared/models/user'
import 'react-datepicker/dist/react-datepicker.css'
import {useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {getIn, useFormik} from 'formik'
import {sendActivationMail, updateUser} from '../../../shared/services/user-service'
import {isNotEmpty} from '../../../../_metronic/helpers'
import {NotificationMessage} from '../../../shared/helper/Notification'
import clsx from 'clsx'
import {listRoles} from '../../../shared/SelectLists'
import Select from 'react-select'
import moment from 'moment'
import {SimpleLoading} from '../../../modules/apps/shared/list/components/loading/SimpleLoading'
import {ListLoading} from '../../../modules/apps/shared/list/components/loading/ListLoading'
import { AddProfileImageModal } from '../overview/userDetails/cards/AddProfileImageModal'

type Props = {
  user: User
  setUser: React.Dispatch<React.SetStateAction<User>>
  asyncFetch: any
}

const EditUserDetails: React.FC<Props> = ({user, setUser, asyncFetch}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)

  const currentUser = JSON.parse(localStorage.getItem('current_user') || '')
  const userPermissionAdmin =
    !!(currentUser?.roles?.filter((item: any) => item === 'admin')?.length > 0) ||
    !!(
      currentUser?.job_position?.we_level === 'WE1' || currentUser?.job_position?.we_level === 'WE2'
    )
  const userPermissionHr =
    !!(
      currentUser?.job_position?.we_level === 'WE1' || currentUser?.job_position?.we_level === 'WE2'
    ) || !!(currentUser?.roles?.filter((item: any) => (item === 'hr' || item === 'hr1' || item === 'hr2' || item === 'hr3'))?.length > 0)


  const userPermissionHrRoles = !!(currentUser?.roles?.filter((item: any) => (item === 'hr'))?.length > 0)

  const [loading, setLoading] = useState(false)
  const [loadingMail, setLoadingMail] = useState(false)
  const [sentMail, setSentMail] = useState(false)
  const [activationButtonLabel, setActivationButtonLabel] = useState(
    intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.SEND_EMAIL'})
  )

  const [role, setRole] = useState(
    user?.roles && user?.roles[0]
      ? {
          value: user?.roles[0],
          label: listRoles.filter((roleValue) => roleValue.value === (user?.roles && user?.roles[0]))[0]?.label,
        }
      : {
          value: null,
          label: null,
        }
  )

  const UserDetailsValidationSchema = Yup.object().shape({
    username: Yup.string()
      .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
      .nullable(),
    email: Yup.string()
      .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
      .email(intl.formatMessage({id: 'WRONG_EMAIL'}))
      .nullable(),
    role: Yup.string().nullable(),
    password: Yup.string()
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.INVALID_PASSWORD'})
      )
      .nullable(),
    password_repeat: Yup.string()
      .nullable()
      .when('password', {
        is: (password: string) => isNotEmpty(password),
        then: (password_repeat) =>
          password_repeat
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
            .oneOf(
              [Yup.ref('password'), null],
              intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.EQUAL_PASSWORD'})
            ),
      }),
  })

  function activateUser(id: any) {
    setLoadingMail(true)
    sendActivationMail(id, '').then((response) => {
      if (response) {
        setLoadingMail(false)
        NotificationMessage(
          intl.formatMessage({id: 'MESSAGE_NOTIFICATION.ACTIVATION_MAIL_SENT_SUCCESSFULLY'}),
          '',
          'success'
        )
        setSentMail(true)
        setActivationButtonLabel(intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.EMAIL_SENT'}))
      }
    })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: user,
    validationSchema: UserDetailsValidationSchema,
    onSubmit: (values) => {
      setLoading(true)
      updateUser(values).then((response: any) => {
        if (response) {
          setUser(values)

          if (userPermissionAdmin || userPermissionHrRoles) {            
            if (user?.roles) {
              user.roles[0] = role?.value || ''
            }
          }

          if (formik.values.is_active === 0 && user.is_active === 1) {
            if (user?.profile) {
              user.profile.end_of_employment_date = moment().format('YYYY-MM-DD')
            }
          }

          formik.values.password = ''
          formik.values.password_repeat = ''
          NotificationMessage(
            intl.formatMessage({
              id: 'MESSAGE_NOTIFICATION.USER_INFORMATION_HAS_BEEN_SUCCESSFULLY_UPDATED!',
            }),
            '',
            'success'
          )
          asyncFetch()
          navigate(`/user/${user?.id}/details/settings/view/`)
        } else {
          NotificationMessage(
            intl.formatMessage({id: 'MESSAGE_NOTIFICATION.UPDATE_FAILED!'}),
            intl.formatMessage({
              id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_UPDATING_USER_INFORMATION!_PLEASE_TRY_AGAIN.',
            }),
            'danger'
          )
          setLoading(false)
        }
      })
    },
  })

  return (
    <div className='card mb-xl-8 card-xl-stretch'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'ACCOUNT.USER_DETAILS'})}</h3>
        </div>
      </div>

      {!loading ? (
        <div id='kt_account_user_details' className='collapse show'>
          <div className='card-body border-top'>
            <div className='row  border-bottom pb-3'>
              <label className='col-sm-6 fw-bold fs-6 mb-1 text-gray-800 pb-2'>
                {intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.PROFILE.PHOTO'})}
              </label>
              <div className='col-sm-6 mb-5'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  onClick={() => setOpenModal(true)}
                >
                  {intl.formatMessage({id: 'PROFILE.IMAGE.ADD_FILE'})}
                </button>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='row border-bottom pb-8'>
                <div className='col-12 col-sm-3 pt-4'>
                  <label className='col-form-label fw-bold fs-6'>
                    {intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.ACTIVATION'})}
                  </label>
                  <div className='d-flex align-items-center form-check form-check-solid form-switch'>
                    <input
                      className='form-check-input cursor-pointer form-check-solid form-switch w-45px h-30px'
                      type='checkbox'
                      name='is_active'
                      checked={formik.values.is_active === 1}
                      onChange={() => {
                        formik.setFieldValue('is_active', formik.values.is_active === 1 ? 0 : 1)
                      }}
                    />
                  </div>
                </div>
                <div className='col-4 col-sm-3 mt-9'>
                  <button
                    type='button'
                    onClick={() => activateUser(user?.id)}
                    disabled={formik.values.is_active === 1 || sentMail}
                    className={clsx(
                      'btn btn-primary',
                      {'d-none': formik.values.is_active === 1},
                      {'d-block': formik.values.is_active === 0}
                    )}
                  >
                    {activationButtonLabel}
                  </button>
                </div>
                <div className='col-8 col-sm-6 mt-9'>
                  <p>
                    {formik.values.is_active
                      ? intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.ACTIVATED_DESCRIPTION'})
                      : intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.ACTIVATE_DESCRIPTION'})}
                  </p>
                </div>
              </div>
              <div className='row py-4'>
                <div className='col-12 col-sm-6 pb-5'>
                  <label className='col-form-label fw-bold fs-6 required'>
                    {intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.USERNAME'})}
                  </label>
                  {formik?.values?.profile && (
                    <input
                      {...formik.getFieldProps('username')}
                      placeholder={intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.USERNAME'})}
                      type='text'
                      name='username'
                      className={
                        getIn(formik.errors, 'username') && getIn(formik.touched, 'username')
                          ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                          : 'form-control form-control-solid mb-3 mb-lg-0'
                      }
                      autoComplete='off'
                    />
                  )}
                  {getIn(formik.errors, 'username') && getIn(formik.touched, 'username') && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{getIn(formik.errors, 'username')}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-12 col-sm-6'>
                  <label className='col-form-label fw-bold fs-6 required'>
                    {intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.EMAIL'})}
                  </label>
                  {formik?.values?.profile && (
                    <input
                      {...formik.getFieldProps('email')}
                      placeholder={intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.EMAIL'})}
                      type='text'
                      name='email'
                      className={
                        getIn(formik.errors, 'email') && getIn(formik.touched, 'email')
                          ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                          : 'form-control form-control-solid mb-3 mb-lg-0'
                      }
                      autoComplete='off'
                    />
                  )}
                  {getIn(formik.errors, 'email') && getIn(formik.touched, 'email') && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{getIn(formik.errors, 'email')}</span>
                      </div>
                    </div>
                  )}
                </div>
                {(userPermissionAdmin || userPermissionHrRoles) && (
                  <div className='col-12 col-sm-6 mb-3'>
                    <label className='col-form-label fw-bold fs-6'>
                      {intl.formatMessage({id: 'USER.ROLE'})}
                    </label>
                    <Select
                      placeholder={intl.formatMessage({id: 'USER.CHOOSE_ROLE'})}
                      defaultValue={{
                        value: role?.value || '',
                        label:
                        role?.label ||
                        intl.formatMessage({id: 'USER.CHOOSE_ROLE'}),
                      }}
                      name='role'
                      className='mb-3 mb-lg-0'
                      classNamePrefix='select'
                      options={listRoles}
                      noOptionsMessage={() => intl.formatMessage({id: 'NO_DATA'})}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          neutral50:
                            localStorage.getItem('kt_theme_mode_menu') === 'dark'
                              ? '#495674'
                              : '#a1a5b7',
                        },
                      })}
                      onChange={(newValue: any | null) => {
                        setRole(newValue?.value)

                        if (!newValue) {
                          formik.setFieldValue('role', 'remove')
                        } else {
                          formik.setFieldValue('role', newValue?.value)
                        }
                      }}
                      menuPortalTarget={document.body}
                      isSearchable={false}
                      isClearable={true}
                    />
                  </div>
                )}
              </div>
              {(user?.id === currentUser?.id || userPermissionAdmin || userPermissionHr) && (
                <>
                  <div className='row border-top pt-7 pb-6'>
                    <div>
                      <label className='fw-bold fs-4'>
                        {intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.CHANGE_PASSWORD'})}
                      </label>
                    </div>
                    <div className='col-12 col-sm-6 mb-3'>
                      <label className='col-form-label fw-bold fs-6'>
                        {intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.NEW_PASSWORD'})}
                      </label>
                      <input
                        {...formik.getFieldProps('password')}
                        placeholder={intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.NEW_PASSWORD'})}
                        type='password'
                        name='password'
                        className={
                          getIn(formik.errors, 'password') && getIn(formik.touched, 'password')
                            ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                            : 'form-control form-control-solid mb-3 mb-lg-0'
                        }
                        autoComplete='off'
                      />
                      {getIn(formik.errors, 'password') && getIn(formik.touched, 'password') && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{getIn(formik.errors, 'password')}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-12 col-sm-6 mb-3'>
                      <label className='col-form-label fw-bold fs-6'>
                        {intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.PASSWORD_REPEAT'})}
                      </label>
                      <input
                        {...formik.getFieldProps('password_repeat')}
                        placeholder={intl.formatMessage({
                          id: 'ACCOUNT.USER_DETAILS.PASSWORD_REPEAT',
                        })}
                        type='password'
                        name='password_repeat'
                        className={
                          getIn(formik.errors, 'password_repeat') &&
                          getIn(formik.touched, 'password_repeat')
                            ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                            : 'form-control form-control-solid mb-3 mb-lg-0'
                        }
                        autoComplete='off'
                      />
                      {getIn(formik.errors, 'password_repeat') &&
                        getIn(formik.touched, 'password_repeat') && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{getIn(formik.errors, 'password_repeat')}</span>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </>
              )}
              <div className='card-footer d-flex justify-content-end'>
                <Link to={`/user/${user.id}/details/settings/view`}>
                  <button type='reset' className='btn btn-light me-3' disabled={loading}>
                    {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                  </button>
                </Link>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading && intl.formatMessage({id: 'BUTTON.SUBMIT'})}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className='position-absolute top-50 start-50 translate-middle'>
          <SimpleLoading />
        </div>
      )}
      {loadingMail && <ListLoading />}
      {openModal && <AddProfileImageModal setOpenModal={setOpenModal} user={user} asyncFetch={asyncFetch}/>}
    </div>
  )
}

export {EditUserDetails}
