import {FC} from 'react'
import {User} from '../../../../../../shared/models/_models'
import {Link} from 'react-router-dom'
import {RoutesHelper} from "../../../../../../shared/helper/RoutesHelper";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useIntl } from 'react-intl';

type Props = {
    user: User
}

const UserInfoCell: FC<Props> = ({user}) => {
    const current_user = JSON.parse(localStorage.getItem('current_user') || '');
    const intl = useIntl();
     
    return (
        <div className='d-flex align-items-center'>
            <div className='d-flex flex-column'>
                <div>
                    <Link
                        to={(RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2', 'hr3']) || current_user?.id === user?.id) ? `/user/${user.id}/details/personal/view` : `/user/${user.id}/details/business/view`}
                        className='text-gray-800 text-hover-primary mb-1'>
                        {user?.first_name} {user?.last_name}
                    </Link>
                    {(user?.is_active === 0) &&
                        <OverlayTrigger placement="top"
                                        overlay={<Tooltip>{intl.formatMessage({id: 'TABLE.USER.INACTIVE'})}</Tooltip>}>
                            <div className="bg-danger ms-2 d-inline-block rounded-circle h-10px w-10px"></div>
                        </OverlayTrigger>
                    }
                </div>
                <span>{user?.job_position?.name}</span>
            </div>
        </div>
    )
}

export {UserInfoCell}
