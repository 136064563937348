import {useListView} from '../../../shared/list/core/ListViewProvider'
import {ListSearchComponent} from '../../../shared/list/components/header/ListSearchComponent'
import {useIntl} from 'react-intl'
import {RequestStatusFilter} from '../../../../../pages/request/RequestPage/RequestStatusFilter'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useLocation, useNavigate} from 'react-router-dom'
import {RoutesHelper} from '../../../../../shared/helper/RoutesHelper'
import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const RequestListHeader = () => {
    const intl = useIntl()
    const location = useLocation()
    const navigate = useNavigate()
    const {setItemIdForUpdate} = useListView()
    const openAddRequestModal = () => {
        setItemIdForUpdate(null)
    }

    return (
        <>
            <div className='card-header row border-0 pt-6'>
                {location.pathname.startsWith('/request') ?
                    <h1 className='d-flex align-items-md-start align-items-center justify-content-center flex-column fs-2qx'>
                        {intl.formatMessage({id: 'REQUESTS'})}
                    </h1>
                    :
                    <h3 className='d-flex align-items-md-start align-items-center justify-content-center flex-column'>
                        {intl.formatMessage({id: 'REQUESTS.ALL'})}
                    </h3>
                }
            </div>
            <div className='card-header border-0 pt-0 pt-md-4'>
                <ListSearchComponent/>
                <div
                    className='card-toolbar d-flex justify-content-sm-center justify-content-md-center justify-content-center justify-content-lg-end col-sm-12 col-12 col-md-12 col-lg-6'>
                    {RoutesHelper(['hr', 'admin', 'hr1', 'hr2', 'hr3']) && (
                        <OverlayTrigger placement="top"
                                        overlay={
                                            <Tooltip>{intl.formatMessage({id: 'TOOLTIP.COMMENT.REQUEST.SETTINGS'})}</Tooltip>}>
                            <button
                                type='button'
                                className='btn btn-primary btn-active-light-primary px-4 mb-1 me-2'
                                onClick={() => navigate('/request/settings')}
                            >
                                <KTSVG path={'/media/icons/duotune/coding/cod001.svg'} className='svg-icon-1 m-0'/>
                            </button>
                        </OverlayTrigger>
                    )}
                    <RequestStatusFilter/>
                    <button
                        type='button'
                        className='btn btn-primary btn-active-light-primary px-4 mb-1'
                        onClick={openAddRequestModal}
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2'/>
                        {intl.formatMessage({id: 'REQUEST.ADD_REQUEST'})}
                    </button>
                </div>
            </div>
        </>
    )
}

export {RequestListHeader}
