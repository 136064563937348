import {UsersListToolbar} from './UserListToolbar';
import {ListSearchComponent} from '../../../../shared/list/components/header/ListSearchComponent';
import {useIntl} from "react-intl";
import {UserFilter} from '../../../../../../pages/users/UsersFilter';
import {RoutesHelper} from '../../../../../../shared/helper/RoutesHelper';
import {ListLoading} from "../../../../shared/list/components/loading/ListLoading";
import {useState} from "react";
import {UsersReport} from "../../../../../../pages/users/UsersReport";

const UsersListHeader = () => {
    const intl = useIntl();
    const [isExporting, setIsExporting] = useState(false);

    return (
        <>
            <div className='card-header row border-0 pt-6'>
                <h1 className='d-flex align-items-md-start align-items-center justify-content-center flex-column fs-2qx'>{intl.formatMessage({id: 'USERS'})}</h1>
            </div>
            <div className='card-header border-0 pt-0 pt-md-4 justify-content-center'>
                <ListSearchComponent/>
                <div
                    className='card-toolbar d-flex justify-content-sm-center justify-content-md-center justify-content-center justify-content-lg-end col-lg-6'>
                    {(RoutesHelper(['hr', 'hr1', 'hr2', 'hr3'])) &&
                        <UsersReport setIsExporting={setIsExporting}/>}
                    <UserFilter/>
                    {(RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2', 'hr3'])) &&
                        <UsersListToolbar/>}
                </div>
            </div>
            {isExporting && <ListLoading/>}
        </>
    )
}

export {UsersListHeader}
