import {FC} from 'react'
import {User} from '../../../../../../shared/models/_models'
import {Link} from "react-router-dom";
import {RoutesHelper} from '../../../../../../shared/helper/RoutesHelper';

type Props = {
    user: User
}
const UserDepartmentCell: FC<Props> = ({user}) => {

    return (
        (RoutesHelper(['WE1', 'WE2', 'WE3', 'admin', 'hr', 'hr1', 'hr2', 'hr3'])) ?
            <div className='d-flex flex-column'>
                {user.job_position?.department?.parent_id ?
                    <>
                        <Link to={`/department/${user?.job_position?.department?.parent?.id}/overview`}
                              className='text-gray-800 text-hover-primary mb-1'>
                            {user?.job_position?.department?.parent?.name}
                        </Link>
                        <Link to={`/department/${user?.job_position?.department?.id}/overview`}
                              className='text-gray-600 text-hover-primary'>
                            {user?.job_position?.department?.name}
                        </Link>
                    </> : <Link to={`/department/${user?.job_position?.department?.id}/overview`}
                                className='text-gray-800 text-hover-primary'>
                        {user?.job_position?.department?.name}
                    </Link>}
            </div>
            : <div className='d-flex align-items-center mb-1 text-gray-800'>
                {user?.job_position?.department?.name}
            </div>
    )
}

export {UserDepartmentCell}
