import {CATEGORY_DIRECT, CATEGORY_INDIRECT, CATEGORY_SALARIED} from "./helper/JobPositionHelper";

const listShifts = [
    {
        value: 'RR',
        label: 'Redovan rad',
    },
    {
        value: 'SR',
        label: 'Smjenski rad',
    },
    {
        value: 'PRV',
        label: 'Rad sa polovinom radnog vremena (4 sata)',
    },
];

const listRrShifts = [
    {
        value: '08:00 16:30',
        label: 'od 08:00h do 16:30h',
    },
    {
        value: '07:00 15:30',
        label: 'od 07:00h do 15:30h',
    },
];

const apuLevels = [
    {
        value: 'APU1',
        label: 'APU 1',
    },
    {
        value: 'APU2',
        label: 'APU 2',
    },
    {
        value: 'APU3',
        label: 'APU 3',
    },
];

const listSrShifts = [
    {
        value: '06:00 14:00',
        label: 'od 06:00h do 14:00h',
    },
    {
        value: '14:00 22:00',
        label: 'od 14:00h do 22:00h',
    },
    {
        value: '22:00 06:00',
        label: 'od 22:00h do 06:00h',
    },
];

const listPrvShifts = [
    {
        value: '08:00 12:30',
        label: 'od 08:00h do 12:30h',
    },
    {
        value: '12:00 16:30',
        label: 'od 12:00h do 16:30h',
    }
];

const listSectors = [
    {
        value: 'technical',
        label: 'Tehnički',
    },
    {
        value: 'commercial',
        label: 'Komercijalni',
    },
    {
        value: 'global',
        label: 'Globalni',
    }
];

const listRoles = [
    {
        value: 'admin',
        label: 'Admin',
    },
    {
        value: 'hr',
        label: 'HR_1',
    },
    {
        value: 'hr1',
        label: 'HR_2',
    },
    {
        value: 'hr2',
        label: 'HR_3',
    },
    {
        value: 'hr3',
        label: 'HR_4',
    }
];

const listWELevels = [
    {
        value: 'WE3',
        label: 'WE 3',
    },
    {
        value: 'WE4',
        label: 'WE 4',
    }
];

const AdditionalDocumentHeader = [
    {
        value: 'file',
        label: 'Dokument',
    },
    {
        value: 'comment',
        label: 'Komentar',
    },
    {
        value: 'delete'
    }
];

const listWELevelJobPosition = [
    {
        value: 'WE3',
        label: 'WE 3',
    },
    {
        value: 'WE4',
        label: 'WE 4',
    },
    {
        value: 'WE5',
        label: 'WE 5',
    },
    {
        value: 'WE6',
        label: 'WE 6',
    }
];


const listJobPositionCategories = [
    {
        value: CATEGORY_SALARIED,
        label: 'Salaried',
    },
    {
        value: CATEGORY_INDIRECT,
        label: 'Indirektni',
    },
    {
        value: CATEGORY_DIRECT,
        label: 'Direktni',
    },
];

const listLanguagesOptions = [
    {value: "Engleski", label: "Engleski"},
    {value: "Njemački", label: "Njemački"}
];

const listDrivingLicenceOptions = [
    {value: 'A1', label: 'A1'},
    {value: 'A', label: 'A'},
    {value: 'B1', label: 'B1'},
    {value: 'B', label: 'B'},
    {value: 'C1', label: 'C1'},
    {value: 'C', label: 'C'},
    {value: 'D1', label: 'D1'},
    {value: 'D', label: 'D'},
    {value: 'BE', label: 'BE'},
    {value: 'C1E', label: 'C1E'},
    {value: 'CE', label: 'CE'},
    {value: 'D1E', label: 'D1E'},
    {value: 'DE', label: 'DE'}
];

const listEmployeeCategory = [
    {value: CATEGORY_DIRECT, label: 'Direktni'},
    {value: CATEGORY_INDIRECT, label: 'Indirektni'},
    {value: CATEGORY_SALARIED, label: 'Salaried'}
];

const listContractRemark = [
    {value: 'Promjena ugovora o radu', label: 'Promjena ugovora o radu'},
    {value: 'Aneks ugovora o radu', label: 'Aneks ugovora o radu'},
    {value: 'Ugovor o međusobnim pravima i obavezama', label: 'Ugovor o međusobnim pravima i obavezama'},
    {value: 'Disciplinski postupak', label: 'Disciplinski postupak'}
];

const listContractType = [
    {value: 'undefined', label: 'Neodređeno'},
    {value: 'defined', label: 'Određeno'}
];

const listContractStatus = [
    {value: 'active', label: 'Aktivan'},
    {value: 'expired', label: 'Istekao'}
];

const listMonths = [
    {value: '1', label: 'Januar'},
    {value: '2', label: 'Februar'},
    {value: '3', label: 'Mart'},
    {value: '4', label: 'April'},
    {value: '5', label: 'Maj'},
    {value: '6', label: 'Jun'},
    {value: '7', label: 'Jul'},
    {value: '8', label: 'Avgust'},
    {value: '9', label: 'Septembar'},
    {value: '10', label: 'Oktobar'},
    {value: '11', label: 'Novembar'},
    {value: '12', label: 'Decembar'},
]

const listNotificationTypes = [
    {value: '', label: 'Sva'},
    {value: 0, label: 'Nepročitana'},
    {value: 1, label: 'Pročitana'},
]

const listRiskTypes = [
    {value: '', label: 'Svi'},
    {value: 1, label: 'Visok'},
    {value: 0, label: 'Nizak'},
]

export {
    listShifts,
    listRrShifts,
    listSrShifts,
    listSectors,
    listRoles,
    listWELevels,
    listJobPositionCategories,
    listLanguagesOptions,
    listDrivingLicenceOptions,
    listEmployeeCategory,
    listContractRemark,
    listContractType,
    listContractStatus,
    listMonths,
    listNotificationTypes,
    listRiskTypes,
    listWELevelJobPosition,
    AdditionalDocumentHeader,
    listPrvShifts,
    apuLevels
}
