/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core';
import {ContractInfoDashboard} from "./elements/ContractInfoDashboard";
import {RequestInfoDashboard} from "./elements/RequestInfoDashboard";
import {UserInfoDashboard} from "./elements/UserInfoDashboard";
import {HRContractDashboard} from "./elements/HRContractDashboard";
import {HRRequestDashboard} from "./elements/HRRequestDashboard";
import {logoutCurrentUser} from "../../modules/auth";
import {CheckTeamLeaderOrTeamCoordinator, RoutesHelper} from '../../shared/helper/RoutesHelper';
import {HRStatisticsDasboard} from './elements/HRStatisticsDasboard';
import {HRProbationContractDashboard} from "./elements/HRProbationContractDashboard";
import {RequestsListWrapper} from "../request/RequestsList";

const DashboardPage: FC = () => {
    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <UserInfoDashboard/>
                </div>
            </div>
            <div className='row'>
                <div className='col-xl-6'>
                    <ContractInfoDashboard/>
                    {(RoutesHelper(['WE4'])) &&
                    <div>
                    <HRContractDashboard/>
                    <HRProbationContractDashboard/>
                    </div>
                    }
                </div>
                <div className='col-xl-6'>
                    <RequestInfoDashboard/>
                </div>
            </div>
            {(CheckTeamLeaderOrTeamCoordinator(['WE5', 'WE6'])) &&
                <div className='row'>
                    <div className='col-12'>
                        <RequestsListWrapper/>
                    </div>
                </div>}
        </>
    )
}

const HRDashboardPage: FC = () => {
    return (
        <>
            <div className='row'>
                {(RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2'])) && <div className='col-xl-12'>
                    <div className='mb-10'>
                        <HRStatisticsDasboard/>
                    </div>
                </div>}
                <div className='col-xl-6'>
                    {(RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2', 'hr3'])) ?
                        <>
                            <HRContractDashboard/>
                            <HRProbationContractDashboard/>
                        </> : <ContractInfoDashboard/>}
                </div>
                <div className='col-xl-6'>
                    <HRRequestDashboard />
                </div>
                {(RoutesHelper(['WE3'])) &&
                    <>
                        <div className='col-xl-6'>
                            <HRContractDashboard />
                        </div>
                        <div className='col-xl-6'>
                            <HRProbationContractDashboard />
                        </div>
                    </>
                }
            </div>
        </>
    )
}

const DashboardWrapper: FC = () => {
    const intl = useIntl();
    let current_user = JSON.parse(localStorage.getItem('current_user') || logoutCurrentUser() as unknown as string);
    const userPermissionHr = !!(current_user?.roles?.filter((item: any) => (item === 'hr' || item === 'hr1' || item === 'hr2' || item === 'hr3'))?.length > 0)
    const userPermissionAdmin = !!(current_user?.roles?.filter((item: any) => item === 'admin')?.length > 0) || !!(current_user?.job_position?.we_level === "WE1" || current_user?.job_position?.we_level === "WE2")

    const employeeWeLevel = current_user?.job_position?.we_level;

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            {(employeeWeLevel === 'WE1' || employeeWeLevel === 'WE2' || employeeWeLevel === 'WE3' || userPermissionAdmin || userPermissionHr) ?
                <HRDashboardPage/> : <DashboardPage/>}
        </>
    )
}

export {DashboardWrapper}
