import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {KTSVG} from "../../../_metronic/helpers";
import {Link, useParams} from "react-router-dom";
import {getContractById} from "../../shared/services/contract-service";
import {ListLoading} from "../../modules/apps/shared/list/components/loading/ListLoading";
import moment from "moment";
import {ContractStatusActionsDropdown} from "./ContractStatusActionsDropdown";
import {ContractModal} from './ContractModal';
import {ContractSolutionDownload} from './ContractSolutionDownload';
import {ProbationHelper} from "../../shared/helper/ProbationHelper";
import {ContractDetailedHelper} from "../../shared/helper/ContractDetailedHelper";
import {CATEGORY_DIRECT, CATEGORY_INDIRECT, CATEGORY_SALARIED} from "../../shared/helper/JobPositionHelper";

const ContractOverview: React.FC = () => {
    const intl = useIntl();
    const contractId = useParams().id || undefined;
    const [contract, setContract] = useState({} as any);
    const [loading, setLoading] = useState(true);

    const direct = intl.formatMessage({id: 'EMPLOYEE_CATEGORY.DIRECT'});
    const indirect = intl.formatMessage({id: 'EMPLOYEE_CATEGORY.INDIRECT'});
    const salaried = intl.formatMessage({id: 'EMPLOYEE_CATEGORY.SALARIED'});

    const definedContract = intl.formatMessage({id: 'CONTRACT.TYPE.DEFINED'});
    const undefinedContract = intl.formatMessage({id: 'CONTRACT.TYPE.UNDEFINED'});

    const technical = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.TECHNICAL'});
    const commercial = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.COMMERCIAL'});
    const global = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.GLOBAL'});

    const contract_active = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.STATUS_TYPE.ACTIVE'});
    const contract_expired = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.STATUS_TYPE.EXPIRED'});

    const currentUser = JSON.parse(localStorage.getItem('current_user') || '');
    const userPermissionHr = !!(currentUser?.job_position?.we_level === "WE1" || currentUser?.job_position?.we_level === "WE2") || !!(currentUser?.roles?.filter((item: any) => (item === 'hr' || item === 'hr1' || item === 'hr2'))?.length > 0);
    const userPermissionAdmin = !!(currentUser?.roles?.filter((item: any) => item === 'admin')?.length > 0) || !!(currentUser?.job_position?.we_level === "WE1" || currentUser?.job_position?.we_level === "WE2");

    const [isDownloading, setIsDownloading] = useState(false);
    const hasSolution = contract?.signed_contract_file_url

    const [openModal, setOpenModal] = useState(false);
    const [end, setEnd] = useState(false);
    const [actions, setActions] = useState([] as any);

    useEffect(() => {
            getContractById(contractId).then((response) => {
                if (response) {
                    setContract(response);
                    setLoading(false);
                    setEnd(false);
                }
            })
        },
        // eslint-disable-next-line
        [end]);

    return (
        <>
            {isDownloading && <ListLoading/>}
            <div className='card mb-5 mb-xl-8'>
                <div className='card-header border-bottom border-2'>
                    <div className='card-title fw-bolder flex-sm-column flex-md-column flex-lg-row flex-column mt-6'>
                        {intl.formatMessage({id: 'DASHBOARD.HR.CONTRACT.INFORMATION'})}
                        {ContractDetailedHelper(contract, loading)}
                        {!contract?.is_finished_probation ? ProbationHelper(contract) : ''}
                    </div>
                    <span
                        className='d-flex align-items-center justify-content-end flex-row'>
                            {contract?.status !== 'expired' ?
                                <ContractStatusActionsDropdown userId={contract?.user_id} setOpenModal={setOpenModal}
                                                               setActions={setActions}
                                                               setIsDownloading={setIsDownloading}
                                                               contract={contract} setEnd={setEnd}/> : ''}
                        {hasSolution && <ContractSolutionDownload contract={contract}/>}
                    </span>

                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-4 col-12'>
                            <div className="d-flex align-items-center mb-2">
                                    <span className='svg-icon me-2 svg-icon-2x'>
                                        <KTSVG path='/media/icons/duotune/communication/com006.svg'
                                               className='svg-icon-2'/>
                                    </span>
                                <span className="fw-bold me-2 text-muted">
                                    {intl.formatMessage({id: 'CONTRACT.USER.NAME'})}:
                                </span>
                                <span className="fw-bold text-hover-primary">
                                    <Link to={`/user/${contract.user_id}/details/personal/view`}
                                          className='text-gray-800 text-hover-primary mb-1'>
                                        {contract.user?.first_name} {contract.user?.last_name}
                                    </Link>
                                </span>
                            </div>
                        </div>
                        <div className='col-md-4 col-12'>
                            <div className="d-flex align-items-center mb-2">
                                    <span className='svg-icon me-2 svg-icon-2x'>
                                        <KTSVG path='/media/icons/duotune/electronics/elc011.svg'
                                               className='svg-icon-2'/>
                                    </span>
                                <span className="text-muted fw-bold me-2">
                                    {intl.formatMessage({id: 'CONTRACT.USER.PHONE_NUMBER'})}:
                                </span>
                                <span className="fw-bold">
                                    {contract.phone_number ? contract?.phone_number : '-'}
                                </span>
                            </div>
                        </div>
                        <div className='col-md-4 col-12'>
                            <div className="d-flex align-items-center mb-2">
                                    <span className='svg-icon me-2 svg-icon-2x'>
                                        <KTSVG path='/media/icons/duotune/maps/map008.svg' className='svg-icon-2'/>
                                    </span>
                                <span className="text-muted fw-bold me-2">
                                    {intl.formatMessage({id: 'CONTRACT.USER.LOCATION'})}:
                                </span>
                                <span className="fw-bold">
                                    {contract?.address && contract?.city ? (contract?.address + ', ' + contract?.city) : '-'}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='mt-5 mb-3'>
                        <label className='fw-bold fs-4'>
                            {intl.formatMessage({id: 'CONTRACT.OVERVIEW'})}
                        </label>
                    </div>
                    {!loading && <div className='row'>
                        <div className='col-lg-6 col-12'>
                            <div className='row'>
                                <label className='col-sm-5 fw-semibold text-muted'>
                                    {intl.formatMessage({id: 'CONTRACT.CODE'})}
                                </label>
                                <div className='col-sm-7 mb-5'>
                                    <span className='fs-6'>
                                        {contract?.code || '-'}
                                    </span>
                                </div>
                                <label className='col-sm-5 fw-semibold text-muted'>
                                    {intl.formatMessage({id: 'CONTRACT.TYPE'})}
                                </label>
                                <div className='col-sm-7 mb-5'>
                                    <span className='fs-6'>
                                        {contract?.type === 'defined' ? definedContract : contract?.type === 'undefined' ? undefinedContract : '-'}
                                    </span>
                                </div>
                                <label className='col-sm-5 fw-semibold text-muted'>
                                    {intl.formatMessage({id: 'CONTRACT.STATUS'})}
                                </label>
                                <div className='col-sm-7 mb-5'>
                                    <span className='fs-6'>
                                        {contract?.status === 'active' ? contract_active : contract_expired}
                                    </span>
                                </div>
                                <label className='col-sm-5 fw-semibold text-muted'>
                                    {intl.formatMessage({id: 'CONTRACT.JOB_POSITION'})}
                                </label>
                                <div className='col-sm-7 mb-5'>
                                    <span className='fs-6'>
                                        {contract?.job_position?.name || '-'}
                                    </span>
                                </div>
                                <label className='col-sm-5 fw-semibold text-muted'>
                                    {intl.formatMessage({id: 'CONTRACT.JOB_POSITION.SECTOR'})}
                                </label>
                                <div className='col-sm-7 mb-5'>
                                    <span className='fs-6'>
                                        {contract?.job_position?.sector === 'technical' ? technical : contract?.job_position?.sector === 'commercial' ?
                                            commercial : contract?.job_position?.sector === 'global' ? global : '-'
                                        }
                                    </span>
                                </div>
                                <label className='col-sm-5 fw-semibold text-muted'>
                                    {intl.formatMessage({id: 'CONTRACT.JOB_POSITION.DEPARTMENT.PARENT'})}
                                </label>
                                <div className='col-sm-7 mb-5'>
                                    <span className='fs-6 text-hover-primary'>
                                     <Link to={`/department/${contract?.job_position?.department?.parent?.id}/overview`}
                                           className='text-dark text-hover-primary mb-1'>
                                        {contract?.job_position?.department?.parent?.name || '-'}
                                    </Link>
                                    </span>
                                </div>
                                <label className='col-sm-5 fw-semibold text-muted'>
                                    {intl.formatMessage({id: 'CONTRACT.JOB_POSITION.DEPARTMENT'})}
                                </label>
                                <div className='col-sm-7 mb-5'>
                                    <span className='fs-6 text-hover-primary'>
                                        <Link to={`/department/${contract?.job_position?.department?.id}/overview`}
                                              className='text-dark text-hover-primary mb-1'>
                                            {contract?.job_position?.department?.name || '-'}
                                        </Link>
                                    </span>
                                </div>
                                <label className='col-sm-5 fw-semibold text-muted'>
                                    {intl.formatMessage({id: 'CONTRACT.JOB_POSITION.CATEGORY'})}
                                </label>
                                <div className='col-sm-7 mb-5'>
                                    <span className='fs-6'>
                                        {contract?.job_position?.category === CATEGORY_DIRECT ? direct : contract?.job_position?.category === CATEGORY_INDIRECT ?
                                            indirect : contract?.job_position?.category === CATEGORY_SALARIED ? salaried : '-'
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-12'>
                            <div className='row'>
                                {(userPermissionHr || userPermissionAdmin) &&
                                    <label className='col-sm-5 fw-semibold text-muted'>
                                        {intl.formatMessage({id: 'CONTRACT.GROSS_SALARY'})}
                                    </label>}
                                {(userPermissionHr || userPermissionAdmin) && <div className='col-sm-7 mb-5'>
                                    <span className='fs-6'>
                                        {contract?.gross_salary ? contract?.gross_salary + ' KM' : '-'}
                                    </span>
                                </div>}
                                <label className='col-sm-5 fw-semibold text-muted'>
                                    {intl.formatMessage({id: 'CONTRACT.HAS_COMPETITION_BAN'})}
                                </label>
                                <div className='col-sm-7 mb-5'>
                                    <span className='fs-6'>
                                        {(contract?.has_competition_ban === null) ? '-' : ((contract?.has_competition_ban) ? intl.formatMessage({id: 'YES'}) : intl.formatMessage({id: 'NO'}))} {" "}
                                    </span>
                                </div>
                                <label className='col-sm-5 fw-semibold text-muted'>
                                    {intl.formatMessage({id: 'CONTRACT.IS_ON_PROBATION'})}
                                </label>
                                <div className='col-sm-7 mb-5'>
                                    <span className='fs-6'>
                                        {contract?.is_on_probation ? intl.formatMessage({id: 'YES'}) : intl.formatMessage({id: 'NO'})} {" "}
                                    </span>
                                    <span
                                        className='fs-6'> {contract?.is_on_probation ? (intl.formatMessage({id: 'CONTRACT.TO'}) + ' ' + moment(contract?.probation_to).format('DD.MM.YYYY.') + intl.formatMessage({id: 'CONTRACT.YEAR'})) : ''} </span>
                                </div>
                                <label className='col-sm-5 fw-semibold text-muted'>
                                    {intl.formatMessage({id: 'CONTRACT.WORKING_TIME'})}
                                </label>
                                <div className='col-sm-7 mb-5'>
                                    <span className='fs-6'>
                                        {contract?.is_full_time ? intl.formatMessage({id: 'CONTRACT.FULL_HOURS_WEEKLY'}) : contract?.weekly_hours + intl.formatMessage({id: 'CONTRACT.PART_HOURS_WEEKLY'})}
                                    </span>
                                </div>
                                <label className='col-sm-5 fw-semibold text-muted'>
                                    {intl.formatMessage({id: 'CONTRACT.DATE'})}
                                </label>
                                <div className='col-sm-7 mb-5'>
                                    <span className='fs-6'>
                                        {contract?.contract_date ? moment(contract?.contract_date).format('DD.MM.YYYY.') + intl.formatMessage({id: 'CONTRACT.YEAR'}) : '-'}
                                    </span>
                                </div>
                                <label className='col-sm-5 fw-semibold text-muted'>
                                    {intl.formatMessage({id: 'CONTRACT.START_DATE'})}
                                </label>
                                <div className='col-sm-7 mb-5'>
                                    <span className='fs-6'>
                                        {contract?.start_date ? moment(contract?.start_date).format('DD.MM.YYYY.') + intl.formatMessage({id: 'CONTRACT.YEAR'}) : '-'}
                                    </span>
                                </div>
                                <label className='col-sm-5 fw-semibold text-muted'>
                                    {intl.formatMessage({id: 'CONTRACT.END_DATE'})}
                                </label>
                                <div className='col-sm-7 mb-5'>
                                    <span className='fs-6'>
                                        {contract?.end_date ? moment(contract?.end_date).format('DD.MM.YYYY.') + intl.formatMessage({id: 'CONTRACT.YEAR'}) : '-'}
                                    </span>
                                </div>
                                {contract.is_resolved ? <label className='col-12 fw-semibold text-uppercase fs-8 mt-13'>
                                    {intl.formatMessage({id: 'CONTRACT.RESOLVE_INFO'})}
                                </label> : ''}
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            {openModal && <ContractModal setOpenModal={setOpenModal} action={actions} contract={contract}/>}
            {loading && <ListLoading/>}
        </>
    )
}

export {ContractOverview}
